export const isDev = process.env.NEXT_PUBLIC_APP_ENV === "dev";
export const isProd = process.env.NEXT_PUBLIC_APP_ENV === "prod";

export const getCookieKey = () => {
  return isProd
    ? "sb-nm-auth-token"
    : isDev
      ? "sb-nm-auth-token-dev"
      : "sb-nm-auth-token-staging";
};
